<template>
  <div class="list row">
    <h2>Poslovnice</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="stations"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Stations"
    >
    </data-table>
  </div>
</template>

<script>
import stationsDataService from "../../services/stations.service";
import DataTable from "../widgets/DataTable.vue";
import StationsForm from "./StationsForm.vue";

export default {
  name: "stations",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        "code",
        "description",
        "metroplex",
        "active",
        "nominal",
        "email",
        "type",
        "sale_type",
        "street",
        "city",
        "state",
        "zip_code",
        "country",
        "edit",
      ],
      headers: {
        code: "Šifra",
        description: "Opis",
        metroplex: "Metroplex",
        active: "Aktivan",
        nominal: "Nominal",
        email: "Email",
        type: "Tip",
        sale_type: "MP/VP",
        street: "Ulica",
        city: "Grad",
        state: "Drzava",
        zip_code: "Poštanski broj",
        country: "Drzava",
        edit: "",
      },
      templates: {
        active(h, row) {
          return row.active == true ? "Yes" : "No";
        },
      },
      actions: {
        edit: { commp: StationsForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: StationsForm,
        params: { data: {}, title: "Nova poslovnica" },
      });
    },
    deleteData(id) {
      stationsDataService
        .delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
