import http from "../utils/http-common";

class StationsDataService {
  getAll() {
    return http.get("/stations");
  }
  getAllForList() {
    return http.get("/stations?limit=100000&ascending=1");
  }

  get(id) {
    return http.get(`/stations/${id}`);
  }
  getByCode(code) {
    return http.get(`/stations/byCode/${code}`);
  }

  create(data) {
    return http.post("/stations", data);
  }

  update(id, data) {
    return http.put(`/stations/${id}`, data);
  }

  delete(id) {
    return http.delete(`/stations/${id}`);
  }
}

export default new StationsDataService();